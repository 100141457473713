import './style.scss';

const galleries = document.querySelectorAll('.gallery');

for (let gallery of galleries) {
    let position = gallery.scrollWidth - gallery.clientWidth;
    let isDown = false;
    let startX;

    // Center images on page load
    gallery.scrollLeft = position / 2;

    gallery.addEventListener('mousedown', e => {
        isDown = true;
        gallery.classList.add('dragging');
        startX = e.pageX - gallery.offsetLeft;
        position = gallery.scrollLeft;
    });

    gallery.addEventListener('mouseleave', () => {
        isDown = false;
        gallery.classList.remove('dragging');
    });

    gallery.addEventListener('mouseup', () => {
        isDown = false;
        gallery.classList.remove('dragging');
    });

    gallery.addEventListener('mousemove', e => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - gallery.offsetLeft;
        const walk = x - startX;
        gallery.scrollLeft = position - walk;
    });
}

const pages = document.getElementsByClassName(window.location.pathname);
if (pages.length) {
    for (let page of pages) {
        page.classList.add('is-active');
    }
}
